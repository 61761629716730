/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  $('#scrollUp').append().html('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="52" height="60" viewbox="0 0 51.96152422706631 60" style="transform: rotate(45deg);transform: rotate(45deg);position: absolute;top: -9px;left: -5px;"><path fill="#c90245" d="M25.980762113533157 0L51.96152422706631 15L51.96152422706631 45L25.980762113533157 60L0 45L0 15Z"></path></svg><i class="fa fa-2x fa-angle-up" aria-hidden="true"></i>');

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  /* ********************************************************
    SEARCH LISTING FORM
  **********************************************************/

$(document).ready(function() {
  //set initial state.
  //$('#terrain').val(this.checked);

  $('#terrain').change(function() {
    if(this.checked) {
      $('#nature_b').addClass('hide_section');
      // var returnVal = confirm("Are you sure?");
      // $(this).prop("checked", returnVal);
    } else {
      $('#nature_b').removeClass('hide_section');
    }
    //$('#terrain').val(this.checked);        
  });

  $('.sb-icon-search').click(function(){
    var e = $('.sb-search-input');
    e.toggleClass('active');
    if (e.hasClass('active')){
      e.focus();
    } else{ e.focusout();}
  });

  if(window.matchMedia("(max-width: 425px)").matches){
    $('.sb-search-input').addClass('active');
  }

  $('.demande-logement-form .step-next').on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      var step = $(this).data('step');
      var current_step = $(this).data('current-step');
      var previous_step = $(this).data('previous-step');
      var checked = false;

      // current_step = '';
      switch (current_step) {
          case "step-situation":
              checked = false;
              $('[name="type-contrat"]').each(function () {
                  if ($(this).prop('checked') === true){
                      checked = true;
                  }
              });
              if (checked === false) {
                  alert('Votre type d\'emploi est obligatoire.');
                  return;
              }
              if (checked === true){
                  if ($('#type-contrat[value="Autre"]').prop('checked') === true && $('#type-contrat-autre').val() === '') {
                      alert('Merci de préciser votre type de contrat.');
                      return;
                  }
                  if ($('#type-contrat[value="CDI"]').prop('checked') === true && $('#cdi-start').val() === ''){
                      alert('La date de début de votre contrat est obligatoire');
                      return;
                  }
                  if ($('#type-contrat[value="CDD"]').prop('checked') === true && ($('#cdd-start').val() === '' || $('#cdd-end').val() === '')){
                      alert('Les dates de votre contrat sont obligatoire');
                      return;
                  }
              }
              checked = false;
              $('[name="etudiant"]').each(function () {
                  if ($(this).prop('checked') === true){
                      checked = true;
                  }
              });
              if (checked === false) {
                  alert('Le statut d\'étudiant est obligatoire.');
                  return;
              }
              checked = false;
              $('[name="boursier"]').each(function () {
                  if ($(this).prop('checked') === true){
                      checked = true;
                  }
              });
              if (checked === false) {
                  alert('Le statut de boursier est obligatoire.');
                  return;
              }else {
                  if ($('#boursier[value="Oui"]').prop('checked') === true && $('#bourse-montant').val() === ''){
                      alert('Le montant de votre bourse est obligatoire.');
                      return;
                  }
              }

              break;
          case "step-logement":
              checked = false;
              $('[name="logement-departement"]').each(function () {
                  if ($(this).prop('checked') === true){
                      checked = true;
                  }
              });
              if (checked === false) {
                  alert('Le département est obligatoire.');
                  return;
              }
              checked = false;
              $('.logement-ville input').each(function () {
                  if ($(this).prop('checked') === true){
                      checked = true;
                  }
              });
              if (checked === false){
                  alert('Vous devez sélectionner au moins une ville.');
                  return;
              }
              if ($('#date-entree').val() === ''){
                  alert('La date d\'entrée dans le logement est obligatoire.');
                  return;
              }
              checked = false;
              $('[name="logement"]').each(function () {
                  if ($(this).prop('checked') === true){
                      checked = true;
                  }
              });
              if (checked === true){
                  if ($('#logement[value="Non"]').prop('checked') === true && $('#logement-actuel').val() === ''){
                      alert('Merci de préciser votre situation actuelle.');
                      return;
                  }
                  if ($('#logement[value="Oui"]').prop('checked') === true && $('#logement-actuel').val() === ''){
                      var logement_checked = false;
                      $('[name="logement-autre"]').each(function () {
                          if ($(this).prop('checked') === true){
                              logement_checked = true;
                          }
                      });
                      if (logement_checked === false){
                          alert('Merci de préciser si vous devez louer un autre logement.');
                          return;
                      }
                  }
              }
              checked = false;
              $('[name="apl"]').each(function () {
                  if ($(this).prop('checked') === true){
                      checked = true;
                  }
              });
              if (checked === true){
                  if ($('#apl[value="Oui"]').prop('checked') === true && $('#apl-montant').val() === ''){
                      alert('Merci de préciser le département de votre CAF de rattachement.');
                      return;
                  }
              }

              // if ($('#numero-allocataire').val() === ''){
              //     if ($('#apl[value="Oui"]').prop('checked') === true){
              //       alert('Merci de préciser votre n° allocataire.');
              //       return;
              //     }
              // }
              // if ($('#numero-allocataire').val().length < 12 || $('#numero-allocataire').val().length > 12){
              //     if ($('#apl[value="Oui"]').prop('checked') === true){
              //       alert('Votre n° allocataire doit être composé de 12 chiffres.');
              //       return;
              //     }
              // }

              checked = false;
              $('[name="moyen-transport"]').each(function () {
                  if ($(this).prop('checked') === true){
                      checked = true;
                  }
              });
              if (checked === true){
                  if ($('#moyen-transport[value="Autre"]').prop('checked') === true && $('#moyen-transport-autre').val() === ''){
                      alert('Merci de préciser votre moyen de transport principal.');
                      return;
                  }
              }
              break;
          case "step-entreprise":
              if ($('#entreprise-nom').val() === ''){
                  alert('Le nom de votre entreprise est obligatoire.');
                  return;
              }
              if ($('#entreprise-rue').val() === '' && $('#entreprise-code-postal').val() === '' && $('#entreprise-ville').val() === ''){
                  alert('L\'adresse de votre entreprise est obligatoire.');
                  return;
              }
              if ($('#entreprise-rue').val() === '' || $('#entreprise-code-postal').val() === '' || $('#entreprise-ville').val() === ''){
                  alert('L\'adresse de votre entreprise est imcomplète.');
                  return;
              }
              break;
          case "step-etat-civil":
              if ($('#nom').val() === ''){
                  alert('Votre nom est obligatoire.');
                  return;
              }
              if ($('#prenom').val() === ''){
                  alert('Votre prénom est obligatoire.');
                  return;
              }
              if ($('#date-naissance').val() === ''){
                  alert('Votre date de naissance est obligatoire.');
                  return;
              }
              checked = false;
              $('[name="situation-familiale"]').each(function () {
                  if ($(this).prop('checked') === true){
                      checked = true;
                  }
              });
              if (checked === false){
                  alert('Votre situation familiale est obligatoire.');
                  return;
              }else {
                  if ($('#bloc-conjoint').hasClass('required')){
                      if ( $('#conjoint-nom').val() === '' || $('#conjoint-prenom').val() === '' || $('#conjoint-date-naissance').val() === '' ) {
                          alert('Merci de préciser les informations sur votre conjoint.');
                          return;
                      }
                  }
              }
              if ($('#rue').val() === '' && $('#code-postal').val() === '' && $('#ville').val() === ''){
                  alert('Votre adresse est obligatoire.');
                  return;
              }
              if ($('#rue').val() === '' || $('#code-postal').val() === '' || $('#ville').val() === ''){
                  alert('Votre adresse est imcomplète.');
                  return;
              }
              if ($('#telephone').val() === ''){
                  alert('Votre n° de téléphone est obligatoire.');
                  return;
              }
              if ($('#email').val() === ''){
                  alert('Votre email est obligatoire.');
                  return;
              }
              var expressionReguliere = /^(([^<>()[]\.,;:s@]+(.[^<>()[]\.,;:s@]+)*)|(.+))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/;
              if ( expressionReguliere.test($('#email').val()) === false ){
                  alert('Le format de votre adresse email n\'est pas correcte.');
                  return;
              }
              break;
      }

      $('.demande-form-step').hide();
      $('.step-bar .step').removeClass('active');
      $('.step-bar .triangle').removeClass('active');
      $('.step-bar .triangle').removeClass('pre-active');
      $('#bar-' + step).addClass('active');
      $('#bart-' + step).addClass('active');
      $('#bart-' + current_step).addClass('pre-active');
      $('#bart-' + previous_step).addClass('pre-active');
      $('#' + step).show();

      $('.step-bar').removeClass('active');
      if (step === 'step-pieces'){
          $('.step-bar').addClass('active');
      }

      document.querySelector('.dossier').scrollIntoView({
          behavior: 'smooth',
          block: "start"
      });

  });

  $('.demande-logement-form #type-contrat').on('change', function () {
      if ( $(this).val() === 'Autre' ){
          $('#type-contrat-autre').removeAttr('readonly');
          $('#type-contrat-autre').css('background-color', '#fff');
      }else {
          $('#type-contrat-autre').prop('readonly' ,true);
          $('#type-contrat-autre').val('');
          $('#type-contrat-autre').css('background-color', '#f3f3f2');
      }
      if ( $(this).val() === 'CDI' ){
          $('.demande-form-cdd').hide();
          $('.demande-form-cdi').show();
          $('#cdd-start').val('');
          $('#cdd-end').val('');
      }else {
          $('.demande-form-cdi').hide();
          $('.demande-form-cdd').show();
          $('#cdi-start').val('');
      }
  });

  $('.demande-logement-form #boursier').on('change', function () {
      if ( $(this).val() === 'Oui' ){
          $('#bourse-montant').removeAttr('readonly');
          $('#bourse-montant').css('background-color', '#fff');
      }else {
          $('#bourse-montant').prop('readonly' ,true);
          $('#bourse-montant').val('');
          $('#bourse-montant').css('background-color', '#f3f3f2');
      }
  });

  $('.demande-logement-form #logement').on('change', function () {
      if ( $(this).val() === 'Non' ){
          $('#logement-actuel').removeAttr('readonly');
          $('#logement-actuel').css('background-color', '#fff');
      }else {
          $('#logement-actuel').prop('readonly' ,true);
          $('#logement-actuel').val('');
          $('#logement-actuel').css('background-color', '#f3f3f2');
      }
  });

  $('.demande-logement-form #apl').on('change', function () {
      if ( $(this).val() === 'Oui' ){
          $('#apl-montant').removeAttr('readonly');
          $('#apl-montant').css('background-color', '#fff');
      }else {
          $('#apl-montant').prop('readonly' ,true);
          $('#apl-montant').val('');
          $('#apl-montant').css('background-color', '#f3f3f2');
      }
  });

  $('.demande-logement-form #moyen-transport').on('change', function () {
      if ( $(this).val() === 'Autre' ){
          $('#moyen-transport-autre').removeAttr('readonly');
          $('#moyen-transport-autre').css('background-color', '#fff');
      }else {
          $('#moyen-transport-autre').prop('readonly' ,true);
          $('#moyen-transport-autre').val('');
          $('#moyen-transport-autre').css('background-color', '#f3f3f2');
      }
  });

  $('.demande-logement-form #reseau').on('change', function () {
      if ( $(this).val() === 'Autre' ){
          $('#reseau-autre').removeAttr('readonly');
          $('#reseau-autre').css('background-color', '#fff');
      }else {
          $('#reseau-autre').prop('readonly' ,true);
          $('#reseau-autre').val('');
          $('#reseau-autre').css('background-color', '#f3f3f2');
      }
  });
  $('.demande-logement-form #situation-familiale').on('change', function () {
      if ( $(this).val() === 'Marié(e)' || $(this).val() === 'Union libre' || $(this).val() === 'Pacsé(e)' ){
          $('#bloc-conjoint').css('display', 'block');
          $('#bloc-conjoint').addClass('required');
      }else {
          $('#bloc-conjoint').css('display', 'none');
          $('#conjoint-nom').val('');
          $('#conjoint-prenom').val('');
          $('#conjoint-date-naissance').val('');
          $('#bloc-conjoint').removeClass('required');
      }
  });
  $('#entreprise-code-postal').on('keyup', function () {
      if ($(this).val().length === 5){
          $.ajax({
              url: 'https://vicopo.selfbuild.fr/cherche/' + $(this).val(),
          })
          .done(function (data) {
              $('#liste-entreprise-ville').empty();
              $('#entreprise-ville').val('');
              data.cities.forEach(function (city) {
                  $('#liste-entreprise-ville').append('<option value="' + city.city + '">');
              });
          });
      }
  });
  $('#ecole-code-postal').on('keyup', function () {
      if ($(this).val().length === 5){
          $.ajax({
              url: 'https://vicopo.selfbuild.fr/cherche/' + $(this).val(),
          })
          .done(function (data) {
              $('#liste-ecole-ville').empty();
              $('#ecole-ville').val('');
              data.cities.forEach(function (city) {
                  $('#liste-ecole-ville').append('<option value="' + city.city + '">');
              });
          });
      }
  });
  $('#code-postal').on('keyup', function () {
      if ($(this).val().length === 5){
          $.ajax({
              url: 'https://vicopo.selfbuild.fr/cherche/' + $(this).val(),
          })
          .done(function (data) {
              $('#liste-ville').empty();
              $('#ville').val('');
              data.cities.forEach(function (city) {
                  $('#liste-ville').append('<option value="' + city.city + '">');
              });
          });
      }
  });

  $('.demande-logement-form #logement-departement').on('change', function () {
      $('.logement-ville').hide();
      switch ($(this).val()) {
          case 'Charente':
                $('#logement-ville-charente').show();
                $('#logement-ville-ayt').prop('checked', false);
                $('#logement-ville-roc').prop('checked', false);
                $('#logement-ville-roy').prop('checked', false);
                $('#logement-ville-bor').prop('checked', false);
                $('#logement-ville-ltb').prop('checked', false);
                $('#logement-ville-lim').prop('checked', false);
              break;
          case 'Charente-Maritime':
              $('#logement-ville-charente-maritime').show();
              $('#logement-ville-ang').prop('checked', false);
              $('#logement-ville-sy').prop('checked', false);
              $('#logement-ville-lc').prop('checked', false);
              $('#logement-ville-cha').prop('checked', false);
              $('#logement-ville-cog').prop('checked', false);
              $('#logement-ville-bor').prop('checked', false);
              $('#logement-ville-ltb').prop('checked', false);
              $('#logement-ville-lim').prop('checked', false);
              break;
          case 'Gironde':
              $('#logement-ville-gironde').show();
              $('#logement-ville-ang').prop('checked', false);
              $('#logement-ville-sy').prop('checked', false);
              $('#logement-ville-lc').prop('checked', false);
              $('#logement-ville-cha').prop('checked', false);
              $('#logement-ville-cog').prop('checked', false);
              $('#logement-ville-ayt').prop('checked', false);
              $('#logement-ville-roc').prop('checked', false);
              $('#logement-ville-roy').prop('checked', false);
              $('#logement-ville-lim').prop('checked', false);
              break;
          case 'Haute-Vienne':
              $('#logement-ville-haute-vienne').show();
              $('#logement-ville-ang').prop('checked', false);
              $('#logement-ville-sy').prop('checked', false);
              $('#logement-ville-lc').prop('checked', false);
              $('#logement-ville-cha').prop('checked', false);
              $('#logement-ville-cog').prop('checked', false);
              $('#logement-ville-ayt').prop('checked', false);
              $('#logement-ville-roc').prop('checked', false);
              $('#logement-ville-roy').prop('checked', false);
              $('#logement-ville-bor').prop('checked', false);
              $('#logement-ville-ltb').prop('checked', false);
              break;
      }
  });

  $('#numero-allocataire').on('keypress', function (event) {
      // Compatibilité IE / Firefox
      // if(!event&&window.event) {
      //     event=window.event;
      // }

      var regex = new RegExp("^[0-9\b]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);

      if (!regex.test(key)) {
          event.preventDefault();
          return false;
      }


      // var ignoredKeys = [8, 9, 37, 38, 39, 40];
      //
      // if (ignoredKeys.indexOf(event.which) >=0 || (event.which >= 48 || event.which <= 57) || event.which === 32 || event.which===0) {
      //     return true;
      // } else {
      //     event.preventDefault();
      // }
    // // IE
    //   if((event.keyCode < 48 || event.keyCode > 57 || event.keyCode === 127) || (event.keyCode != 8 || event.keyCode != 0)) {
    //       event.returnValue = false;
    //       event.cancelBubble = true;
    //   }
    // // DOM
    //   if((event.which < 48 || event.which > 57 ||  event.keyCode === 127) || (event.keyCode != 8 || event.keyCode != 0)) {
    //       event.preventDefault();
    //       event.stopPropagation();
    //   }
      // if ($('#numero-allocataire').val().length === 12 ){
      //     if (typeof window.getSelection != "undefined") {
      //         if ( window.getSelection().toString().length >= 1 ){
      //             return;
      //         }
      //     }
      //     event.returnValue = false;
      //     event.cancelBubble = true;
      //     event.preventDefault();
      //     event.stopPropagation();
      // }
  });
  $('#entreprise-code-postal').on('keypress', function (event) {
      // Compatibilité IE / Firefox
    //   if(!event&&window.event) {
    //       event=window.event;
    //   }

      var regex = new RegExp("^[0-9\b]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);

      if (!regex.test(key)) {
          event.preventDefault();
          return false;
      }

    // // IE
    //   if(event.keyCode < 48 || event.keyCode > 57 || event.keyCode  === 8 || event.keyCode === 127) {
    //       event.returnValue = false;
    //       event.cancelBubble = true;
    //   }
    // // DOM
    //   if(event.which < 48 || event.which > 57 ||  event.keyCode === 127) {
    //     if(event.keyCode !== 8 || event.keyCode !== 0) {
    //         event.preventDefault();
    //         event.stopPropagation();
    //     }
    //   }
      // if ($('#entreprise-code-postal').val().length === 5){
      //     if (typeof window.getSelection != "undefined") {
      //         if ( window.getSelection().toString().length >= 1 ){
      //             return;
      //         }
      //     }
      //     event.returnValue = false;
      //     event.cancelBubble = true;
      //     event.preventDefault();
      //     event.stopPropagation();
      // }
  });
  $('#ecole-code-postal').on('keypress', function (event) {
      // Compatibilité IE / Firefox
    //   if(!event&&window.event) {
    //       event=window.event;
    //   }

      var regex = new RegExp("^[0-9\b]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);

      if (!regex.test(key)) {
          event.preventDefault();
          return false;
      }

    // // IE
    //   if(event.keyCode < 48 || event.keyCode > 57 || event.keyCode  === 8 || event.keyCode === 127) {
    //       event.returnValue = false;
    //       event.cancelBubble = true;
    //   }
    // // DOM
    //   if(event.which < 48 || event.which > 57 || event.keyCode === 8 || event.keyCode === 127) {
    //       event.preventDefault();
    //       event.stopPropagation();
    //   }
      // if ($('#ecole-code-postal').val().length === 5){
      //     if (typeof window.getSelection != "undefined") {
      //         if ( window.getSelection().toString().length >= 1 ){
      //             return;
      //         }
      //     }
      //     event.returnValue = false;
      //     event.cancelBubble = true;
      //     event.preventDefault();
      //     event.stopPropagation();
      // }
  });
  $('#code-postal').on('keypress', function (event) {
      // Compatibilité IE / Firefox
    //   if(!event&&window.event) {
    //       event=window.event;
    //   }

      var regex = new RegExp("^[0-9\b]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);

      if (!regex.test(key)) {
          event.preventDefault();
          return false;
      }

    // // IE
    //   if(event.keyCode < 48 || event.keyCode > 57 || event.keyCode  === 8 || event.keyCode === 127) {
    //       event.returnValue = false;
    //       event.cancelBubble = true;
    //   }
    // // DOM
    //   if(event.which < 48 || event.which > 57 || event.keyCode === 8 || event.keyCode === 127) {
    //       event.preventDefault();
    //       event.stopPropagation();
    //   }
      // if ($('#code-postal').val().length === 5){
      //     if (typeof window.getSelection != "undefined") {
      //         if ( window.getSelection().toString().length >= 1 ){
      //             return;
      //         }
      //     }
      //     event.returnValue = false;
      //     event.cancelBubble = true;
      //     event.preventDefault();
      //     event.stopPropagation();
      // }
  });
  $('#telephone').on('keypress', function (event) {
      // Compatibilité IE / Firefox
    //   if(!event&&window.event) {
    //       event=window.event;
    //   }

      var regex = new RegExp("^[0-9\b]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);

      if (!regex.test(key)) {
          event.preventDefault();
          return false;
      }

    // // IE
    //   if(event.keyCode < 48 || event.keyCode > 57 || event.keyCode  === 8 || event.keyCode === 127) {
    //       event.returnValue = false;
    //       event.cancelBubble = true;
    //   }
    // // DOM
    //   if(event.which < 48 || event.which > 57 || event.keyCode === 8 || event.keyCode === 127) {
    //       event.preventDefault();
    //       event.stopPropagation();
    //   }
    //   if ($('#telephone').val().length === 10){
    //       if (typeof window.getSelection != "undefined") {
    //           if ( window.getSelection().toString().length >= 1 ){
    //               return;
    //           }
    //       }
    //       event.returnValue = false;
    //       event.cancelBubble = true;
    //       event.preventDefault();
    //       event.stopPropagation();
    //   }
  });

  $("#step-pieces input[type=file]").each(function() {
    $(this).on('change', function (){
      var fileName = $(this).val();
      var files_number = 0;
       for (var i = 0; i < $(this)[0].files.length; i++)
       {
         files_number ++;
       }
      if (files_number > 1){
          $(this).next('.modal').text("Vos documents ont bien été téléchargés");
      } else {
          $(this).next('.modal').text("Votre document a bien été téléchargé");
      }
        $('#liste-' + $(this).attr('id')).empty();
        for (var x = 0; x < $(this)[0].files.length; x++)
        {
            $('#liste-' + $(this).attr('id')).append('<p class="liste-document"><i class="fa fa-check-circle-o" aria-hidden="true"></i>' + $(this)[0].files[x].name + '</p>');
            console.log($(this)[0].files);
        }
    }); 
  });

  /* Is safari */
  var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (is_safari) {
    $('body').addClass('is_safari');
  }

});

})(jQuery); // Fully reference jQuery after this point.