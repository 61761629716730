/*
    Template:  Sheltek Real Estate HTML5 Template
    Author: http://devitems.com/
    Version: 1
    Design and Developed by: http://devitems.com/
    NOTE: If you have any note put here. 
*/
/*================================================
[  Table of contents  ]
================================================
    1. jQuery MeanMenu
    2. wow js active
    3. scrollUp jquery active
    4. Nivo Slider 
    5. Price Slider
    6. slick carousel 
    6. tooltip
    7. Service Carousel
    8. Agents Carousel
    9. Testimonial Carousel
    10. Blog Carousel
    11. Brand Carousel
    12. Blog Carousel
    13. counter
    14. Background Toutube Video 
    15. STICKY sticky-header
    16. Tarteaucitron
 
======================================
[ End table content ]
======================================*/


(function($) {
    "use strict";

    /*-------------------------------------------
        1. jQuery MeanMenu
    --------------------------------------------- */
    jQuery('nav#dropdown').meanmenu();

    /*-------------------------------------------
        2. wow js active
    --------------------------------------------- */
    new WOW().init();

    /*-------------------------------------------
        3. scrollUp jquery active
    --------------------------------------------- */
    $.scrollUp({
        scrollText: '<i class="fa fa-angle-up"></i>',
        easingType: 'linear',
        scrollSpeed: 900,
        animation: 'fade'
    });

    /*-------------------------------------------
        4. Nivo Slider
    --------------------------------------------- */
    /*$('#ensign-nivoslider-3').nivoSlider({
        // effect: 'fade',
        effect: 'random',
        slices: 15,
        boxCols: 8,
        boxRows: 4,
        animSpeed: 500,
        pauseTime: 5000,
        prevText: 'p<br/>r<br/>e<br/>v', 
        nextText: 'n<br/>e<br/>x<br/>t',
        startSlide: 0,
        directionNav: true,
        controlNav: true,
        controlNavThumbs: false,
        pauseOnHover: true,
        manualAdvance: false
    });*/

     /*-------------------------------------------
        4. Flex Slider
    --------------------------------------------- */
    $('.listing-slider').flexslider({
        animation: "slide",
        controlNav: false,
    });
    /* ********************************************
        5. Price Slider
    ******************************************** */
    $( "#slider-range" ).slider({
        range: true,
        min: $('#amount').data('pricemin'),
        max: $('#amount').data('pricemax'),
        values: [ $('#amount').data('pricemin'), $('#amount').data('pricemax') ],
        slide: function( event, ui ) {
            $( "#amount" ).val( ui.values[ 0 ] + "€ - " + ui.values[ 1 ] + " €");
            $( "#price_min" ).val( ui.values[ 0 ]);
            $( "#price_max" ).val( ui.values[ 1 ]);
        }
    });
    $( "#amount" ).val( $( "#slider-range" ).slider( "values", 0 ) +
    " € - " + $( "#slider-range" ).slider( "values", 1 ) + " €"); 

    /*************************
        6. tooltip
    *************************/
    $('[data-toggle="tooltip"]').tooltip();

    /*************************
        7. Service Carousel
    *************************/ 
    $('.service-carousel').slick({
        arrows: false,
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 3,
        responsive: [
            { breakpoint: 991, settings: { slidesToShow: 3, slidesToScroll: 2 } }, // Tablet
            { breakpoint: 767, settings: { slidesToShow: 2, slidesToScroll: 1 } }, // Large Mobile
            { breakpoint: 479, settings: { slidesToShow: 1, slidesToScroll: 1 } }  // Small Mobile
        ]
    });
    /*************************
        8. Agents Carousel
    *************************/ 
    $('.agents-carousel').slick({
        arrows: false,
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 3,
        responsive: [
            { breakpoint: 991, settings: { slidesToShow: 3, slidesToScroll: 2 } }, // Tablet
            { breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1 } }, // Large Mobile
            { breakpoint: 479, settings: { slidesToShow: 1, slidesToScroll: 1 } }  // Small Mobile
        ]
    });

    /*************************
        9. Testimonial Carousel
    *************************/ 
    $('.testimonial-carousel').slick({
        arrows: false,
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    /*************************
        10. Blog Carousel
    *************************/ 
    $('.blog-carousel').slick({
        arrows: false,
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 2,
        responsive: [
            { breakpoint: 991, settings: { slidesToShow: 2, slidesToScroll: 1 } }, // Tablet
            { breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1 } }, // Large Mobile
            { breakpoint: 479, settings: { slidesToShow: 1, slidesToScroll: 1 } }  // Small Mobile
        ]
    });

    /*************************
        11. Brand Carousel
    *************************/ 
    $('.brand-carousel').slick({
        arrows: false,
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 4,
        responsive: [
            { breakpoint: 1169, settings: { slidesToShow: 4, slidesToScroll: 3 } }, // Medium Device
            { breakpoint: 991, settings: { slidesToShow: 3, slidesToScroll: 2 } }, // Tablet
            { breakpoint: 767, settings: { slidesToShow: 2, slidesToScroll: 1 } }, // Large Mobile
            { breakpoint: 479, settings: { slidesToShow: 1, slidesToScroll: 1 } }  // Small Mobile
        ]
    });

    /*************************
        12. Blog Carousel
    *************************/ 
    $('.pro-details-carousel').slick({
        arrows: false,
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 3,
        responsive: [
            { breakpoint: 991, settings: { slidesToShow: 4, slidesToScroll: 3 } }, // Tablet
            { breakpoint: 767, settings: { slidesToShow: 3, slidesToScroll: 2 } }, // Large Mobile
            { breakpoint: 479, settings: { slidesToShow: 2, slidesToScroll: 2 } }  // Small Mobile
        ]
    });

    /*************************
        13. counter
    *************************/ 
    $('.counter').counterUp({
        delay: 10,
        time: 1000
    });
    
    /* ********************************************
        14. Background Toutube Video 
    ******************************************** */
    $(".youtube-bg").YTPlayer({
        videoURL:"Sz_1tkcU0Co",
        containment:'.youtube-bg',
        mute:true,
        loop:true,
    });

    /* ********************************************
        15. STICKY sticky-header
    ******************************************** */
    var hth = $('.header-top-bar').height();
    $(window).on('scroll', function() {
        if ($(this).scrollTop() > hth){  
            $('#sticky-header').addClass("sticky");
        }
        else{
            $('#sticky-header').removeClass("sticky");
        }
    });

    /* ********************
        16. Tarteaucitron
    *********************** */

    /* changement des libellé */
    tarteaucitron.init({
        "privacyUrl": "/politique-de-cookies", /* Privacy policy url */

        "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
        "cookieName": "tarteaucitron", /* Cookie name */

        "orientation": "bottom", /* Banner position (top - bottom) */
        "showAlertSmall": true, /* Show the small banner on bottom right */
        "cookieslist": true, /* Show the cookie list */

        "adblocker": false, /* Show a Warning if an adblocker is detected */
        "DenyAllCta" : false, /* Show the deny all button */
        "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
        "highPrivacy": true, /* Disable auto consent */
        "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

        "removeCredit": false, /* Remove credit link */
        "moreInfoLink": true, /* Show more info link */
        "useExternalCss": true, /* If false, the tarteaucitron.css file will be loaded */

        //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for subdomain website */

        "readmoreLink": "/cookiespolicy", /* Change the default readmore link pointing to tarteaucitron.io */
        
        "mandatory": false /* Show a message about mandatory cookies */
    });


    /*** Ajout des cookies paramétrables ***/
    tarteaucitron.job = tarteaucitron.job || [];
   
    //vimeo
    tarteaucitron.job.push('vuid');

    //youtube
    tarteaucitron.job.push('youtube');
 //matomocloud
 tarteaucitron.user.matomoId = 1 ; 
 (tarteaucitron.job = tarteaucitron.job || []).push('matomo');    //    

/* ********************************************************
    SEND AJAX FORM
******************************************************** */

if ($('#send-message').length > 0){

    var busy = null;

    $('#send-message').click(function(){
        var error = false,
            form = $(this).parent('form');

        form.find('[required]').each(function(){
            if ($.trim($(this).val()) == '') {
                $(this).css('border-color','#ff0000');
                error = true;
            } else {
                $(this).css('border-color','#dadbd8');
            }
        });

        $('#send-message').html('Traitement en cours...');

        if ( !error ) {
            if (busy)
                busy.abort();

            

            busy = $.ajax({
                url: ajaxurl,
                type: 'post',
                data: form.serialize(),
                success: function(response){
                    if (response == 'success') {
                        form[0].reset();
                        $('#noty').attr('class','noty-success').html('Votre message a bien été envoyé.');
                        $('#ms').slideUp();
                    }
                    if (response == 'error'){
                        $('#noty').attr('class','noty-error').html('Une erreur est survenue.');   
                        $('#send-message').html('Envoyer');
                    }
                }
            });
        } else {
            $('#noty').attr('class','noty-error').html('Merci de renseigner tous les champs.'); 
            $('#send-message').html('Envoyer');
        }

        return false;
    });
}






})(jQuery);

/* ********************************************
    16. GOOGLE autocomplete
    ******************************************** */
    function initializeAutocomplete(id) {

        var input = document.getElementById(id);
        var autocomplete = new google.maps.places.Autocomplete(input, {
            types: ['(regions)'],
            componentRestrictions: {
                country: 'fr'
            }
        });
            google.maps.event.addListener(autocomplete, 'place_changed', function () {
            var place = autocomplete.getPlace();
            document.getElementById('searchlat').value = place.geometry.location.lat();
            document.getElementById('searchlng').value = place.geometry.location.lng();
        });
    }